<template>
  <div class="loader-container">
    <overlay-loader :loading="isLoading" />
    <order-list-store-cards
      :pending="orders.pending"
      :delivery="orders.delivery"
      :delivered="orders.delivered"
    />
    <filters :display="[]" />
    <section class="main-content">
      <div class="grid-x grid-margin-x grid-margin-y">
        <div class="order-list__actions-container">
          <div class="order-list__actions">
            <transition name="fade">
              <div v-if="showCards" style="animation-duration: 0.5s"
                class="order-list__search">
                <hcc-input v-model="textSearch"
                  :placeholder="$t('common.search')" @input="searchItems"
                  @keyup.enter="searchOrder({ value: textSearch, key: true })"
                  icon>
                  <template #icon>
                    <magnify-icon />
                  </template>
                </hcc-input>
              </div>
            </transition>
            <button-filter class="filter-orders"
              v-on:changeOrder="onFiltersChangedOrders"
              :display="displayFilters" />
          </div>
          <div class="order-list__info">
            <div class="order-list__filters">
              <hcc-badge fill customClass="badge__agent" size="lg">
                <template #badgeText>
                  <span>{{ $t('filter.status') }}:&nbsp;</span>
                  &nbsp;
                  <span class="info-agent" v-if="!filtersStatus.length"> {{
                      $t("filter.all")
                  }}</span>
                  <span class="info-agent" v-else
                    v-for="(status, index) in filtersStatus" :key="index">
                    {{ status }}
                    <template v-if="filtersStatus.length - 1 !== index">
                      ,&nbsp;
                    </template>
                  </span>
                </template>
              </hcc-badge>
              <hcc-badge fill customClass="badge__company" size="lg">
                <template #badgeText>
                  <div>{{ $t('dashboard.orders.payment-method') }}:&nbsp;</div>
                  <div class="info-company">{{ filterPaymentMethod
                      ? filterPaymentMethod
                      : $t("filter.all")
                  }}</div>
                </template>
              </hcc-badge>
              <hcc-badge fill customClass="badge__campaign" size="lg">
                <template #badgeText>
                  <div>{{ $t('dashboard.orders.method-of-delivery') }}:&nbsp;
                  </div>
                  <div class="info-campaign">{{
                      filterDeliveryMethod
                        ? filterDeliveryMethod
                        : $t("filter.all")
                  }}</div>
                </template>
              </hcc-badge>
            </div>
            <change-view-button :disabled="rows.length === 0"
              @change-view="changeView" />
          </div>
        </div>
        <hcc-table v-if="!showCards" class=" cell small-12" :rowsPerPage="5"
          :columns="columns" :rows="rows" :totalPages="pagination.total"
          :actualPage="pagination.page" @pageChanged="changePage"
          @search="searchOrder" :pagination="pagination.total > 5">
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'order'">
              {{ props.row.order }}
            </span>
            <span v-if="props.column.field === 'car'">
              <span class="order-time">
                <clock-outline-icon /> {{ props.row.createdAt }}
              </span>
              <div class="order-list-products">
                <clipboard-list-outline />
                <span>{{ $t('shop.sale.products') }}:</span>
                <b>{{ props.row.car.reduce((total, { quantity }) => total +
                    quantity, 0)
                }}</b>
              </div>
            </span>
            <span class="order-list-status"
              v-if="props.column.field === 'statecart'">
              <circle-icon :class="statusCar(props.row.statecart.name)" />
              {{ statusCarText(props.row.statecart.name) }}

            </span>
            <span v-if="props.column.field === 'orderDate'">
              {{ props.row.orderDate }}
            </span>
            <span v-if="props.column.field === 'paymentMethod'">
              <credit-card v-if="props.row.paymentMethod === 'card'"
                class="order-list-column-icon order-list-column-icon__card" />
              <cash-multiple v-else
                class="order-list-column-icon order-list-column-icon__cash" />
              <span class="order-list-column-text">
                {{ paymentMethod(props.row.paymentMethod) }}
              </span>
            </span>
            <span v-if="props.column.field === 'deliveryStatus'">
              <walk class="order-list-column-icon"
                v-if="Number(props.row.deliveryStatus) === 0" />
              <motorbike class="order-list-column-icon" v-else />
              <br>
              <span class="order-list-column-text">
                {{ methodDelivery(props.row.deliveryStatus) }}
              </span>
            </span>
            <span v-if="props.column.field === 'actions'">
              <hcc-button-icon @click="viewOrderDetails(props.row)"
                v-tooltip="$t('shop.sale.see-details')">
                <eye-icon />
              </hcc-button-icon>

              <hcc-button-icon @click="updateStateCart(props.row)"
                v-tooltip="buttonChangeStatus(props.row)"
                v-if="buttonChangeStatus(props.row)">
                <component :is="iconChangeStatus(props.row)" />
              </hcc-button-icon>
              <hcc-button-icon v-if="[1, 2, 3, 6].includes(props.row.statecart.key)"
                @click="updateStateCart(props.row, 5)"
                v-tooltip="$t('shared-components.table.cancel')">
                <cancel-icon />
              </hcc-button-icon>
            </span>
          </template>
        </hcc-table>
        <orders-list-cards v-else :orders="rows" :total="pagination.total"
          :actualPage="pagination.page" @change-page="changePage"
          @view-detail="viewOrderDetails" @update-status="updateStateCart"
          @cancel="updateStateCart($event, 5)" />
      </div>
    </section>
    <order-detail-modal :data="dataOrder" />
    <hcc-confirmation />
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import HccTable from '@/components/shared/HccTable/index.vue';
import HccButtonIcon from '@/components/shared/HccButtonIcon/index.vue';
import OrderDetailModal from '@/components/sale/OrderDetailsModal.vue';
import saleChangeStatusGql from '@/graphql/mutations/sale/saleChangeStatus.gql';
import OverlayLoader from '@/components/loaders/OverlayLoader.vue';
import newOrderGql from '@/graphql/suscriptions/newOrder.gql';
import Filters from '@/components/Filters.vue';
import HccBadge from '@/components/shared/HccBadge/index.vue';
import HccInput from '@/components/shared/HccInput/index.vue';
import HccConfirmation from '@/components/shared/HccConfirmation/index.vue';
import ChangeViewButton from '@/components/ChangeViewButton.vue';
import OrdersListCards from './OrdersListCards.vue';
import OrderListStoreCards from './OrderListStoreCards.vue';

export default {
  props: {
    filters: {
      required: true,
    },
  },
  components: {
    HccTable,
    HccButtonIcon,
    OrderDetailModal,
    OverlayLoader,
    Filters,
    ButtonFilter: () => import('@/components/ButtonFilter.vue'),
    HccBadge,
    HccConfirmation,
    HccInput,
    ChangeViewButton,
    OrdersListCards,
    OrderListStoreCards,
  },
  data() {
    return {
      idOrderSearch: '',
      dataOrder: {},
      pagination: {
        total: 0,
        page: 1,
      },
      isLoading: true,
      textSearch: '',
      displayFilters: ['statusOrder', 'paymentMethodOrder', 'deliveryMethodOrder'],
      filtersOrders: {},
      filtersStatus: [],
      filterPaymentMethod: '',
      filterDeliveryMethod: '',
      showCards: false,
      delivered: 0,
    };
  },
  apollo: {
    $subscribe: {
      newOrder: {
        query: newOrderGql,
        variables() {
          return {
            companyId: this.filters.companyId,
            campaignId: this.filters.campaignId,
          };
        },
        skip() {
          return !this.filters;
        },
        result({ data }) {
          const { newOrder: order } = data;
          const status = this.filtersOrders.orderStatus || [];
          this.updateOrder({ order, status });
        },
        error() {
          this.$toasted.global.error(this.$t('alerts.unexpectedError'));
        },
      },
    },
  },
  watch: {
    filters() {
      this.initAll();
    },
  },
  computed: {
    ...mapState({
      orders: state => state.sale.sales,
    }),
    columns() {
      return [{
        label: this.$t('dashboard.orders.order-number'),
        field: 'order',
      }, {
        label: this.$t('dashboard.orders.order-breakdown'),
        field: 'car',
      }, {
        label: this.$t('dashboard.orders.condition'),
        field: 'statecart',
      }, {
        label: this.$t('dashboard.orders.order-date'),
        field: 'orderDate',
      }, {
        label: this.$t('dashboard.orders.payment-method'),
        field: 'paymentMethod',
      }, {
        label: this.$t('dashboard.orders.method-of-delivery'),
        field: 'deliveryStatus',
      }, {
        label: this.$t('shared-components.table.actions'),
        field: 'actions',
        thClass: 'custom-th-class',
        tdClass: 'custom-td-class',
      }];
    },
    rows() {
      if (!this.orders || !this.orders.data) {
        return [];
      }

      return this.orders.data.map(({ order, pendingAt, ...row }) => ({
        ...row,
        order: `# ${order}`,
        createdAt: this.$moment(pendingAt).local().fromNow(),
        orderDate: this.$moment(pendingAt).local().format('DD/MM/YYYY HH:mm'),
      }));
    },
  },
  methods: {
    ...mapMutations({
      updateOrder: 'sale/updateItem',
    }),
    ...mapActions({
      getOrdersAdmin: 'sale/getOrdersAdmin',
    }),
    async onFiltersChangedOrders(value) {
      this.filtersOrders = value;
      this.filterPaymentMethod = this.filtersOrders.paymentMethod?.text;
      this.filterDeliveryMethod = this.filtersOrders.deliveryMethod?.text;
      this.filtersStatus = this.filtersOrders.orderStatus.map(item => item.name);
      this.filtersOrders.paymentMethod = this.filtersOrders.paymentMethod?.key;
      this.filtersOrders.deliveryMethod = this.filtersOrders.deliveryMethod?.key;
      this.filtersOrders.orderStatus = this.filtersOrders.orderStatus.map(item => item.key);
      this.pagination.page = 1;
      await this.initAll();
    },
    searchOrder({ value, key }) {
      if (key || value.length === 0) {
        this.pagination.page = 1;
        this.textSearch = value;
        this.initAll();
      }
    },
    methodDelivery(status) {
      if (Number(status)) return this.$t('shop.sale.at-home');
      return this.$t('shop.sale.go-through-the-order');
    },
    paymentMethod(method) {
      if (method === 'card') return this.$t('shop.sale.card');
      return this.$t('shop.sale.cash');
    },
    changePage(newPage) {
      if (this.pagination.page !== newPage) {
        this.pagination.page = newPage;
        this.initAll();
      }
    },
    statusCarText(status) {
      if (status === 'Pendiente') return this.$t('shop.sale.pending');
      if (status === 'En preparación') return this.$t('shop.sale.in-preparation');
      if (status === 'Listo') return this.$t('shop.sale.done');
      if (status === 'En reparto') return this.$t('shop.sale.as-delivered');
      if (status === 'Entregado') return this.$t('shop.sale.delivered');
      return this.$t('shop.sale.cancel');
    },
    statusCar(status) {
      if (status === 'Pendiente') return 'pending';
      if (status === 'En preparación') return 'cast-in';
      if (status === 'Listo') return 'ready';
      if (status === 'En reparto') return 'cast-in';
      if (status === 'Entregado') return 'delivered';
      return 'cancel';
    },
    async initAll(load = true) {
      this.isLoading = load;
      const query = {
        company: this.filters.companyId,
        campaign: this.filters.campaignId,
        descendingOrder: true,
        length: this.showCards ? 6 : 5,
        page: this.pagination.page,
        start: this.filters.startDate || this.filters.start,
        end: this.filters.endDate || this.filters.end,
        channelId: this.filters.channelId,
        keysStatesCarts: this.filtersOrders.orderStatus || [],
        dataSearch: this.textSearch,
        deliveryMethod: this.filtersOrders.deliveryMethod || '',
        paymentMethod: this.filtersOrders.paymentMethod || '',
      };
      await this.getOrdersAdmin(query);
      if (!this.orders.data.length && this.pagination.page > 1) {
        this.pagination.page = this.pagination.page - 1;
        this.initAll();
      }
      this.pagination.total = this.orders.total;
      this.isLoading = false;
    },
    async updateStateCart(sale, statecart = undefined) {
      let status = this.buttonChangeStatus(sale);
      if (statecart === 5) {
        status = this.$t('shared-components.table.cancel');
      }
      this.$modal.show('confirmation', {
        title: this.$t('messages.cancel-order'),
        description: this.$t('messages.description-cancel', { status: status.toLowerCase() }),
        variant: 'default',
        confirm: async () => {
          await this.$apollo.mutate({
            mutation: saleChangeStatusGql,
            variables: { id: sale.id, statecart },
          });
          await this.initAll();
        },
      });
    },
    viewOrderDetails(row) {
      this.dataOrder = row;
      this.idOrderSearch = row.id;
      this.$modal.show('detail-order');
    },
    buttonChangeStatus({ statecart, deliveryStatus }) {
      if (statecart.name === 'Pendiente') {
        return this.$t('shop.sale.mark-in-preparation');
      }

      if (statecart.name === 'En preparación') {
        return this.$t('shop.sale.mark-done');
      }

      if (statecart.name === 'Listo' && +deliveryStatus >= 1) {
        return this.$t('shop.sale.mark-as-delivered');
      }

      if (statecart.name === 'En reparto' || (+deliveryStatus === 0 && statecart.name === 'Listo')) {
        return this.$t('shop.sale.mark-delivered');
      }

      return null;
    },
    iconChangeStatus({ statecart, deliveryStatus }) {
      if (statecart.name === 'Pendiente') {
        return 'pot-mix';
      }

      if (statecart.name === 'En preparación') {
        return 'check-circle';
      }

      if (statecart.name === 'Listo' && +deliveryStatus >= 1) {
        return 'bike';
      }

      if (statecart.name === 'En reparto' || (+deliveryStatus === 0 && statecart.name === 'Listo')) {
        return 'handshake-outline';
      }

      return '';
    },
    changeView(showCards) {
      this.showCards = showCards;
      if (this.showCards) {
        this.initAll();
      }
    },
    searchItems() {
      if (this.textSearch === '') {
        this.searchOrder({ value: '', key: undefined });
      }
    },
  },
  async mounted() {
    if (this.filters !== null) {
      await this.initAll();
    }
  },
};
</script>

<style scoped lang="scss">
@import "~styles/components/filters.scss";
@import "~styles/components/table-sale.scss";
@import "~styles/shared/confirmation.scss";

.filter-orders {
  margin-left: 10px;
}
</style>
